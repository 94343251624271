<template>
  <div class="bg bg12">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";

export default {
  name: "groupPhoto",
  components: {ClassBut},
  data() {
    return {

    }
  },
  methods: {
    forward() {
      this.$router.replace({
        name: 'ConferenceClassList'
      })
      // this.$router.push({
      //   name: 'PageTwentiethFirth',
      //   query: this.$route.query,
      // })
    },
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
</style>
